<!-- 学员编辑 -->
<template>
    <div class="EditTrainee">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员编辑</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="序号">
                <el-input v-model="form.number" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="报名日期" prop="signDate">
                <el-date-picker
                      v-model="form.signDate"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="交费日期" prop="payDate">
                <el-date-picker
                      v-model="form.payDate"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="学员卡号" prop="studentCard">
                <el-input v-model="form.studentCard" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="学生姓名" prop="studentName">
                <el-input v-model="form.studentName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="报班">
                <el-select v-model="form.report" placeholder="请选择状态" :clearable="true">
                    <el-option
                      v-for="item in form.reportOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="学生描述">
                <el-input v-model="form.describe" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="职务">
                <el-input v-model="form.duties" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="学年">
                <el-input v-model="form.schoolYear" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="内部备注">
                <el-input v-model="form.remarks" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="退(转)费">
                <el-input v-model="form.retreat" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="退(转)学日期">
                <el-date-picker
                      v-model="form.dropOut"
                      type="date"
                      placeholder="选择日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="报名上课次数">
                <el-input v-model="form.frequency" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="优惠前应收学杂费">
                <el-input v-model="form.B_discount" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="转班带入学费">
                <el-input v-model="form.bring" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="优惠率">
                <el-input v-model="form.rate" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="让利金额">
                <el-input v-model="form.yield" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="优惠后应收学杂费">
                <el-input v-model="form.A_discount" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="其中学费">
                <el-input v-model="form.tuition" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="杂费">
                <el-input v-model="form.incidental" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="经办人员" prop="handle">
                <el-input v-model="form.handle" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="学籍类">
                <el-select v-model="form.xueji" placeholder="请选择状态" :clearable="true">
                    <el-option
                      v-for="item in form.xuejiOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="支付方式">
                <el-select v-model="form.payment" placeholder="请选择状态" :clearable="true">
                    <el-option
                      v-for="item in form.paymentOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="定金">
                <el-input v-model="form.deposit" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="实收金额">
                <el-input v-model="form.receipts" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    number:"",//序号
                    signDate:"",//报名日期
                    payDate:"",//交费日期
                    studentCard:"",//学员卡号
                    studentName:"",//学生姓名
                    report:"",//报班
                    reportOpt:[
                        {label: "报本班", value: "1"},
                        {label: "报多班", value: "2"}
                    ],//报班选项
                    describe:"",//学生描述
                    duties:"",//职务
                    schoolYear:"",//学年
                    remarks:"",//内部备注
                    retreat:"",//退(转)费
                    dropOut:"",//退(转)学日期
                    frequency:"",//报名上课次数
                    B_discount:"",//优惠前应收学杂费
                    bring:"",//转班带入学费
                    rate:"",//优惠率
                    yield:"",//让利金额
                    A_discount:"",//优惠后应收学杂费
                    tuition:"",//其中学费
                    incidental:"",//杂费
                    handle:"",//经办人员
                    xueji:"",//学籍类
                    xuejiOpt:[
                        {label: "开班入学", value: "1"},
                        {label: "插班入学", value: "2"},
                        {label: "转班入学", value: "3"},
                        {label: "结业-1", value: "4"},
                        {label: "结业-2", value: "5"},
                        {label: "结业-3", value: "6"},
                        {label: "退学", value: "7"},
                        {label: "转学", value: "8"}
                    ],//学籍类选项
                    payment:"",//支付方式
                    paymentOpt:[
                        {label: "现金", value: "1"},
                        {label: "银行转账", value: "2"},
                        {label: "支票", value: "3"},
                        {label: "借记卡", value: "4"},
                        {label: "信用卡", value: "5"},
                        {label: "移动支付", value: "6"}
                    ],//支付方式选项
                    deposit:"",//定金
                    receipts:""//实收金额
                },
                rules:{
                   signDate:[
                         { type: 'date', required: true, message: '报名日期不能为空', trigger: 'change' }
                    ], 
                   payDate:[
                         { type: 'date', required: true, message: '交费日期不能为空', trigger: 'change' }
                    ], 
                   studentCard:[
                         { required: true, message: '学员卡号不能为空', trigger: 'blur' },
                    ],
                   studentName:[
                         { required: true, message: '学生姓名不能为空', trigger: 'blur' },
                    ],
                }
            }
        },
        created() {
            
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.$router.push({
                    path: "trainee"
                })
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .EditTrainee{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
